import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'sections/Layout/SEO';
import EuropaPark from 'mocks/webflow/europa-park';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/webflow-europa-park.scss';

const seo = {
  title: 'Europa - Park Resort | Survey Promo | Screver',
  metaDesc:
    'Look at the Europa - Park Resort website with our Screver Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
  opengraphUrl: 'https://screver.com/cases/cx/europa-park/',
};

const EuropaParkPage = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document)`}</script>

        <style>{`.w-webflow-badge{opacity:0!important;pointer-events:none}.popover-container{border-radius:24px;overflow:hidden;transform:translate3d(0,0,0);z-index:10}.button{background-color:#786936!important}.popover-container{width:660px!important;height:460px!important}@media (max-width:1000px){.popover-container{width:90%!important;position:relative}}`}</style>
      </Helmet>

      <SEO seo={seo} themeColor="#1a2c56" />

      <div
        className="webflow webflow-europa-park"
        dangerouslySetInnerHTML={{ __html: EuropaPark }}
      ></div>
    </>
  );
};

export default EuropaParkPage;
